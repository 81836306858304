/* eslint-disable sort-keys */
import {
  alpha,
  Grid,
  Typography
} from "@mui/material";
import { LinkProps } from "@mui/material/Link";
import {
  createTheme,
  PaletteOptions,
  SimplePaletteColorOptions,
  styled
} from "@mui/material/styles";
import jsonColors from "./envColors.json";
import { LinkBehavior } from "../components";

interface DefaultPaletteOptions extends PaletteOptions {
  primary?: SimplePaletteColorOptions;
  secondary?: SimplePaletteColorOptions
}

const defaultColors = {
  backgroundAccent: "#A6FF03",
  backgroundDisabled: "#696969",
  backgroundDisabledAlternative: "#D9D9D9",
  backgroundPrimary: "#1B1B1B",
  backgroundPrimaryCta: "#F43E63",
  backgroundPrimaryLighter: "#151515",
  backgroundPrimaryLighterAlternative:  "#151515",
  backgroundSaved: "#FFFF00",
  backgroundSecondary: "#FFFFFF",
  backgroundSecondaryAlternative: "#FFFFFF",
  backgroundSecondaryComplete: "#0FD9FF",
  backgroundWarning: "#FF6B00",

  border: "#696969",
  borderAccent: "#A6FF03",
  borderError: "#EB5757",
  borderPrimaryCta: "#F43E63",
  borderSecondaryComplete: "#0FD9FF",
  borderTag: "#FFFFFF",
  borderWarning: "#FF6B00",

  textDisabled: "#696969",
  textDisabledAlternative: "#D9D9D9",
  textError: "#EB5757",
  textInput: "#151515",
  textMandatory: "#A6FF03",
  textPrimary: "#FFFFFF",
  textPrimaryAlternative: "#151515",
  textPrimaryCta: "#F43E63",
  textTag: "#FFFFFF",
  textWarning: "#FF6B00",

  systemDisabled: "#D9D9D9",
  systemPrimary01: "#FFFFFF",
  systemPrimary01Alternative01: "#FFFFFF",
  systemPrimary01Alternative02: "#FFFFFF",
  systemPrimary02: "#F43E63",
  systemSecondary01: "#A6FF03",
  systemSecondary02: "#0FD9FF",
  systemSecondary03: "#FF6B00",
  systemSecondary04: "#FFFF00",
  systemSecondary05: "#151515",
  systemSecondary05Alternative: "#151515",

  accentVariantA: "#A6FF03", // bitterLemon
  accentVariantB: "#0FD9FF", // vividSkyBlue
  accentVariantC: "#FF6B00", //springOrange06
  accentVariantD: "#A6FF03",
  accentVariantE: "#A6FF03",
  drawerWidgetBg: "#FFFFFF",
  white: "#FFFFFF",

  //GRADIENTS
  gradient01Start: "25,25,31",
  gradient01End: "62,62,67",

  gradient02Start: "27,27,27",
  gradient02End: "27,27,27",

  gradient03Start: "27,27,27",
  gradient03End: "27,27,27",

  gradient04Start: "27,27,27",
  gradient04End: "27,27,27",

  gradient05Start: "27,27,27",
  gradient05End: "27,27,27",

  //BOX SHADOW
  boxShadowFilter: "10px 10px 80px 0px rgba(21, 21, 21, 0.40)"
};
const localStorageColors = localStorage.getItem("storageColors") as string;


const enviromentalColors = localStorageColors ?  JSON.parse(localStorageColors) as Partial<typeof defaultColors>  :
jsonColors as Partial<typeof defaultColors>;

const customColors = Object.assign(defaultColors, enviromentalColors);
type CustomColors = typeof customColors

const linearGradients = {
  gradientB: `linear-gradient(180deg, rgba(${customColors.gradient01Start}, 1) 0%,
    rgba(${customColors.gradient01End}, 1) 100%)`,

  gradientC: `linear-gradient(180deg, rgba(${customColors.gradient02Start}, 0.3) 0%,
    rgba(${customColors.gradient02End}, 1.0) 100%)`,

  gradientCAlternative01: `linear-gradient(180deg, rgba(${customColors.gradient03Start}, 0.1) 0%,
    rgba(${customColors.gradient03End}, 0.7) 100%)`,

  gradientCAlternative02: `linear-gradient(180deg, rgba(${customColors.gradient03Start}, 0.1) 0%,
    rgba(${customColors.gradient03End}, 0.7) 50%)`,

  gradientCAlternative03: `linear-gradient(240deg, rgba(${customColors.gradient03Start}, 0.1) 0%,
    rgba(${customColors.gradient03End}, 0.7) 100%)`,

  gradientD: `linear-gradient(150deg, rgba(${customColors.gradient04Start}, 0.4) 0%,
    rgba(${customColors.gradient04End}, 1) 100%)`,

  gradientDAlternative01: `linear-gradient(0deg, rgba(${customColors.gradient05Start}, 0) 50%,
    rgba(${customColors.gradient05End}, 1) 100%)`,

  gradientDAlternative02: `linear-gradient(0deg, rgba(${customColors.gradient05Start}, 0) 70%,
    rgba(${customColors.gradient05End}, 1) 100%)`,

  gradientDAlternative03: `linear-gradient(180deg, rgba(${customColors.gradient05Start}, 0) 50%,
    rgba(${customColors.gradient05End}, 1) 100%)`,

  gradientDAlternative04: `linear-gradient(180deg, rgba(${customColors.gradient05Start}, 0) 20%,
    rgba(${customColors.gradient05End}, 1) 40%)`,

  gradientDAlternative05: `linear-gradient(270deg, rgba(${customColors.gradient05Start}, 0) 30%,
    rgba(${customColors.gradient05End}, 1) 100%)`,

  gradientDAlternative06: `linear-gradient(270deg, rgba(${customColors.gradient05Start}, 0) 0%,
    rgba(${customColors.gradient05End}, 1) 100%)`,

  gradientDAlternative07: `linear-gradient(90deg, rgba(${customColors.gradient05Start}, 0) 0%,
    rgba(${customColors.gradient05End}, 1) 100%)`,

  gradientDAlternative08:  `linear-gradient(180deg, rgba(${customColors.gradient05Start}, 0.4) 20%,
    rgba(${customColors.gradient05End}, 1) 100%)`,

  gradientDAlternative09: `linear-gradient(180deg, rgba(${customColors.gradient04Start}, 0.4) 0%,
    rgba(${customColors.gradient04End}, 1) 100%)`
};
type LinearGradients = typeof linearGradients & Record<string, string>

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: false; // removes the `xl` breakpoint
    max: true; // adds the `max` breakpoint
  }

  interface Theme {
    customColors: CustomColors,
    linearGradients: LinearGradients,
    palette: DefaultPaletteOptions
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    customColors: CustomColors,
    linearGradients: LinearGradients,
    palette?: PaletteOptions
  }
}

export const domain1 = createTheme({
  // cfr. https://mui.com/material-ui/customization
  breakpoints: {
    values: {
      /* eslint-disable sort-keys */
      xs: 0,
      sm: 769, //tablet-mobile
      md: 900,
      lg: 1200,
      max: 1440
      /* eslint-enable sort-keys */
    }
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(4px)",
          background: alpha(customColors.drawerWidgetBg, 0.5),
          boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",

          "&.MuiBackdrop-invisible": {
            backdropFilter: "none",
            backgroundColor: "transparent",

            "&.backdrop-search": {
              backdropFilter: "blur(4px)",
              background: alpha(customColors.drawerWidgetBg, 0.5)
            }
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: "Inter";
          letter-spacing:0.5px;
          overscroll-behavior: none;

          a {
            color:${customColors.systemPrimary02};
            font-weight:500
           };

          .mask {
            &::before {
              content:"";
              backdrop-filter: none;
              background:transparent;
              pointer-events:none;
              position:fixed;
              top:0;
              bottom:0;
              right:0;
              left:0;
              transition:all .3s linear;

            }
            &.has-mask {

              &::before  {
                content:"";
                backdrop-filter: blur(4px);
                background: ${alpha(customColors.drawerWidgetBg, 0.5)};
                box-shadow: ${alpha(customColors.drawerWidgetBg, 0.5)};
                pointer-events:auto;
                z-index:1200
              }

          }
          }

          .scrollable {
            overflow-y:scroll;

            &:hover {
              ::-webkit-scrollbar-thumb {
                background:${customColors.systemPrimary01};
              }
            };

            ::-webkit-scrollbar {
              width: 6px;
            };

            ::-webkit-scrollbar-thumb {
              background-color: transparent;
              border-radius: 8px;
            }
          }
        

          div#freshworks-container {
            position:relative !important;
            z-index:1200 !important;
          };

          @media (max-width: 599.95px) {
            div#freshworks-container iframe#launcher-frame {
              bottom:60px !important;

              .launcher-button.hupbHS {
                background-color:red !important;
              }
            }

          }
        }
      `
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },

    MuiDrawer:{
      styleOverrides: {
        root: {

          "&.drawer-search-mobile":{
            zIndex:"1300"
          },

          "&.chatBot-drawer": {
            zIndex:1400,

            ".MuiModal-backdrop": {
              zIndex:1400
            },

            "@media (max-width: 599.95px)":{


              bottom:"56px",

              ".MuiModal-backdrop": {
                bottom:"77.83px"
              }

            }
          }


        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {

          "&.popover-notification": {

            ".MuiPopover-paper": {
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              borderTop: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0
            }
          },

          ".MuiPopover-paper": {
            border: `1px solid ${customColors.border}`,
            borderRadius: "8px",

            ".MuiMenu-list": {
              padding: 0
            }
          },

          "&.popover-searchBar": {
            top: "88px",

            ".MuiModal-backdrop": {
              backdropFilter: "blur(4px)",
              border: "none",
              borderRadius: "0px",
              boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
              top: "88px"
            },

            ".MuiPopover-paper": {
              border: "none",
              borderRadius: "2px",
              top: "0px !important"
            }
          },

          "&.popover-community, &.popover-goals, &.popover-goals-success, &.popover-modal-history": {
            zIndex:"1400",

            ".MuiModal-backdrop": {

              backdropFilter: "blur(4px)",
              background: "rgba(255, 255, 255, 0.3)",
              border: "none",
              borderRadius: "0px",
              boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)"
            },
            ".MuiPopover-paper": {
              border: "none",
              borderRadius: "2px"
            }
          }
        }
      }
    }
  },
  customColors,
  linearGradients,
  palette: {
    background: {
      default: customColors.backgroundPrimary,
      paper: customColors.backgroundPrimaryLighter
    },
    divider: customColors.border,
    info: {
      main: customColors.textPrimary
    },
    primary: {
      main: customColors.textPrimaryCta
    },
    secondary: {
      main: customColors.textMandatory
    },
    text: {
      primary: customColors.textPrimary,
      secondary: "#757575"
    }
  },
  typography: {
    fontFamily: "Inter"
  }
});

export const TypographyDescription = styled(Typography)(({ theme }) => ({
  color: customColors.textPrimary,
  fontSize: "1.25rem",
  fontWeight: "400",
  lineHeight: "1.5",
  padding: "0 0 24px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    wordBreak: "break-word"
  }
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary?.main,
  fontSize: "1.5rem",
  fontWeight: "700",
  lineHeight: "1.5",
  padding: " 0 0 24px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem"
  }
}));

export const WrapperSmall = styled(Grid)(() => ({
  borderBottom: `1px solid  ${customColors.border}`,
  padding: "0px 6.25rem 4.875rem"

}));

