import bg from  "./assets/communityTopic.png";
import { getEnvironmentVariables } from "./utils/general";
const { clientId, userPoolId } = getEnvironmentVariables();


export const cognitoConfig = {
  ClientId: clientId,
  UserPoolId: userPoolId
};

/* MAGIC STYLING NUMNBERS */

export const MAIN_LATERAL_PADDING = 42; //px
export const STATUS_BOX_OVERHANG = 8; //px

/* ENUMS */

export const AUTH_MODE = {
  Cognito: "Cognito",
  SSO: "SSO"
} as const;

export const CAROUSEL_TYPE = {
  DEFAULT: "DEFAULT",
  HERO: "HERO",
  ZOOM: "ZOOM",
  ZOOM_RELATED: "ZOOM_RELATED"
} as const;

export const COGNITO_CHALLENGE = {
  CUSTOM_CHALLENGE: "CUSTOM_CHALLENGE", // MFA
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED"
  // PASSWORD_VERIFIER: "PASSWORD_VERIFIER" // amazon-cognito-identity-js handles this for us
} as const;

export const CONTENTS_NAME = {
  BADGES:"badges",
  CATEGORIES:"categories",
  CATEGORY_LINKED:"categoryLinkedLearningObjects",
  EXPIRING: "expiringLearningObjects",
  FAVOURITES:"favouriteLearningObjects",
  FOR_YOU:"forYouLearningObjects",
  JOB:"jobLinkedLearningObjects",
  LAST_FAVOURITES:"lastFavouriteLearningObjects",
  LATEST:"latestLearningObjects",
  LEARNING: "learningObjects",
  MAIN:"mainLearningObjects",
  MANDATORY:"mandatoryLearningObjects",
  NEXT:"nextLearningObjects",
  ON_GOING:"onGoingLearningObjects",
  OTHERS:"otherLearningObjects",
  SKILLS:"skillsLinkedLearningObjects",
  SUGGESTED:"suggestedLearningObjects",
  TOP_RATED:"topRatedLearningObjects"
} as const;

export const CONTENT_TYPE = {
  ASYNC:"ASYNC", // digital
  BLENDED: "BLENDED", // mixed
  SMART_LEARNING_SLOT: "SMART_LEARNING_SLOT",
  SYNC:"SYNC" // live & virtual
} as const;

export const ENROLL_TYPE = {
  AUTO_ENROLL:"AUTO_ENROLL",
  REQUESTED_AUTO_ENROLL:"REQUESTED_AUTO_ENROLL",
  TOP_DOWN: "TOP_DOWN"
} as const;

export const ERRORS = {
  UNAUTHORIZED: "UNAUTHORIZED",
  ERR_DATASET_EMPTY: "ERR_DATASET_EMPTY"
} as const;

export const ECM_TYPE = {
  BLENDED:"BLENDED",
  FAD :"FAD",
  FSC:"FSC", 
  RES:"RES" 
};
export const LEVEL = {
  COURSE: "COURSE",
  DA_EXTERNAL_RES:"DA_EXTERNAL_RES",
  DA_FINAL_BALANCE:"DA_FINAL_BALANCE",
  DA_GOALS:"DA_GOALS",
  DA_LINKEDIN:"DA_LINKEDIN",
  DA_MATERIAL:"DA_MATERIAL",
  DA_MEETING:"DA_MEETING",
  DA_PHYSICAL_CLASS:"DA_PHYSICAL_CLASS",
  DA_SCORM:"DA_SCORM",
  DA_SURVEY:"DA_SURVEY",
  DA_URL_RES:"DA_URL_RES",
  DA_VIRTUAL_CLASS:"DA_VIRTUAL_CLASS",
  DA_XAPI:"DA_XAPI",
  DA_YOUTUBE:"DA_YOUTUBE",
  PATH:"PATH"
				
} as const;

export const NAVBAR_ITEMS = {
  AGENDA: "AGENDA",
  BOOKMARKS: "BOOKMARKS",
  COMMUNITY: "COMMUNITY",
  EXPLORE: "EXPLORE",
  FOR_YOU: "FOR_YOU",
  NONE: "NONE" // eg. /profile
} as const;

export const NOTIFICATION_TYPE = {
  BADGE:"BADGE", //Disponibilità attestato/badge
  ENROLL:"ENROLL", //iscrizione
  LEARN_REM:"LEARNREM", // Reminder formazione obbligatoria
  LIVE_EVENT:"LIVEVENT", //Reminder eventi live
  REMINDER:"REMINDER", //solleciti
  RESET:"RESET", //reset psw
  SUGGEST:"SUGGEST", //suggerimenti
  WELCOME:"WELCOME", //Welcome
  WELCOME_NP:"WELCOMENP" //Welcome
} as const;

export const PAGE_NAME = {
  AGENDA_OVERVIEW: "AGENDA_OVERVIEW",
  EXPLORE_OVERVIEW: "EXPLORE_OVERVIEW",
  FAVOURITES_OVERVIEW: "FAVOURITES_OVERVIEW",
  FOR_YOU_OVERVIEW: "FOR_YOU_OVERVIEW",
  WIDGET_AGENDA: "WIDGET_AGENDA",
  WIDGET_BADGES: "WIDGET_BADGES",
  WIDGET_ONGOING: "WIDGET_ONGOING"
} as const;

export const PERMISSIONS = {
  "admin.dashboard.access": "admin.dashboard.access"
} as const;
 
export const PRESIGNED_TYPE = {
  ASSIGNMENT:"ASSIGNMENT",
  CORRECTION:"CORRECTION"
};

export const PREREQ_TYPE = {
  FIRST: "FIRST",
  FREE: "FREE",
  LAST_LOCKED: "LAST_LOCKED",
  SEQUENTIAL: "SEQUENTIAL"
} as const;

export const PROFESSIONAL_LEVEL = {
  MANAGER: "MANAGER",
  PROFESSIONAL: "PROFESSIONAL"
} as const;

export const POST_TYPE = {
  FREE: "FREE",
  SHARED: "SHARED"
} as const;

export const POST_TYPE_FILTER = {
  DISCUSSION:"discussion",
  FREE: "free",
  SHARED: "shared"
};

export const ROLES = {
  BUSINESS_ADMIN: "BUSINESS_ADMIN",
  LEARNER:"LEARNER",
  PRODUCT_OWNER: "PRODUCT_OWNER"
} as const;

export const SERVICE_TYPES = {
  "sliderTopContentExplore": "sliderTopContentExplore"
} as const;

export const SOURCE = {
  BRIGHTCOVE: "BRIGHTCOVE",
  SCORM: "SCORM"
} as const;

export const STATUS_ACTIVITY = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  TRANSITIONING: "TRANSITIONING"
} as const;

export const STATUS_LO = {
  C: "C", //COMPLETED
  E: "E", //ENROLLED
  N: "N", //NOT ENROLLED
  P: "P", //PROGRESS
  R: "R", //REFUSED
  T: "T",
  W: "W" //WAITING APPROVATION
} as const;

export const TREND = {
  DOWN: "DOWN",
  EQUALS: "EQUALS",
  UP: "UP"
} as const;

export const TREND_GOALS = {
  ALMOST_THERE: "ALMOST_THERE",
  GOAL_ACHIEVED: "GOAL_ACHIEVED",
  GOAL_NOT_ACHIEVED:"GOAL_NOT_ACHIEVED",
  ON_LINE: "ON_LINE",
  UNDER_LINE: "UNDER_LINE",
  UNKNOWN : "UNKNOWN"

} as const;


export const TAB_ITEMS_PROFILE = {
  GOALS: "GOALS",
  KNOWLEDGE: "KNOWLEDGE",
  PREFERENCES: "PREFERENCES",
  SECTOR: "SECTOR"
} as const;

export const TAB_ITEMS_PROFILE_CASE = {
  ALL_ONBOARDING: "ALL_ONBOARDING",
  NO_ONBOARDING: "NO_ONBOARDING",
  SIMPLE_ONBOARDING:"SIMPLE_ONBOARDING"
} as const;


export const TAB_ITEMS_PROFILE_CONFIG = {
  [TAB_ITEMS_PROFILE_CASE.NO_ONBOARDING]:{
    tabItems: [
      TAB_ITEMS_PROFILE.GOALS
    ]
  },
  [TAB_ITEMS_PROFILE_CASE.ALL_ONBOARDING]:{
    tabItems: [
      TAB_ITEMS_PROFILE.GOALS,
      TAB_ITEMS_PROFILE.SECTOR,
      TAB_ITEMS_PROFILE.KNOWLEDGE,
      TAB_ITEMS_PROFILE.PREFERENCES
    ]
  },
  [TAB_ITEMS_PROFILE_CASE.SIMPLE_ONBOARDING]:{
    tabItems: [
      TAB_ITEMS_PROFILE.GOALS,
      TAB_ITEMS_PROFILE.PREFERENCES
    ]
  }

  
} as const;

export const TAB_ITEMS = {
  ACTIVITIES: "ACTIVITIES",
  COMMUNITY: "COMMUNITY",
  DETAIL: "DETAIL",
  EDITIONS: "EDITIONS",
  EXERCISES: "EXERCISES",
  INSTRUMENTS: "INSTRUMENTS",
  RELATED: "RELATED"
} as const;


export const TAB_ITEMS_CONFIG = {
  [LEVEL.COURSE] :{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.PATH]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_EXTERNAL_RES]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_FINAL_BALANCE]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_GOALS]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_LINKEDIN]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_MATERIAL]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_MEETING]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_SCORM]: {
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_URL_RES]:{
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_XAPI]: {
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_YOUTUBE]: {
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_SURVEY]: {
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED
    ]
  },
  [LEVEL.DA_VIRTUAL_CLASS]: {
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.EDITIONS,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED,
      TAB_ITEMS.EXERCISES
    ]
  },
  [LEVEL.DA_PHYSICAL_CLASS]: {
    tabItems: [
      TAB_ITEMS.ACTIVITIES,
      TAB_ITEMS.DETAIL,
      TAB_ITEMS.EDITIONS,
      TAB_ITEMS.INSTRUMENTS,
      TAB_ITEMS.COMMUNITY,
      TAB_ITEMS.RELATED,
      TAB_ITEMS.EXERCISES
    ]
  }
} as const;

// these are in the same order intended for the ui
/* eslint-disable sort-keys */
export const TIME_SLOT = {
  NO_PREFERENCE: "NO_PREFERENCE",
  MORNING: "MORNING",
  EVENING: "EVENING",
  NIGHT: "NIGHT"
} as const;
/* eslint-enable sort-keys */

/** LISTS */

export const CAPABILITY_TYPE = {
  IN_DEPTH:"IN_DEPTH",
  TRAINING:"TRAINING"
};

export const CAPABILITY_BUCKETS = [
  {
    id:CAPABILITY_TYPE.IN_DEPTH,
    label:"Approfondimento"
  },
  {
    id:CAPABILITY_TYPE.TRAINING,
    label:"Allenamento"
  }

];


export const SERVICES = [
  // "accredible",
  "carConteSpeciDurata",
  // "discourse",
  "favourites",
  "freshDesk",
  "funcFavourites",
  "funcRating",
  "sliderTopContentAgenda",
  "sliderTopContentExplore",
  "smartLearning",
  "trainingMandatory",
  "visCarBestRating",
  "visCarCategory",
  "visCarExpiring",
  "visCarNext",
  "visCarSuggested",
  "visCarTrainingByTrade",
  "visCarTrainingTransversal",
  "visForyou",
  "widgetBadge",
  "widgetCalendar",
  "widgetContinueLearning",
  "widgetLeaderboard"
];

export const LEARNING_LEVEL = {
  ADVANCED:"ADVANCED",
  BASIC:"BASIC", 
  INTERMEDIATE:"INTERMEDIATE"
};

export const ONBOARDING_TYPE = {
  ALL: "ALL", 
  ONLY_CAPABILITIES:"ONLY_CAPABILITIES",
  ONLY_EXECUTIVES:"ONLY_EXECUTIVES",
  ONLY_PERSONAL:"ONLY_PERSONAL",
  
  CAPABILITIES_EXECUTIVES:"CAPABILITIES_EXECUTIVES",
  PERSONAL_CAPABILITIES:"PERSONAL_CAPABILITIES",
  PERSONAL_EXECUTIVES:"PERSONAL_EXECUTIVES"
};

export const SKILL_BUCKETS = [
  {
    id:LEARNING_LEVEL.BASIC,
    label:"Principiante"
  },
  {
    id:LEARNING_LEVEL.INTERMEDIATE,
    label:"Intermedio"
  },
  {
    id:LEARNING_LEVEL.ADVANCED,
    label: "Avanzato"
  }

];


export const CAPABILITIES = [
  { 
    cover: bg,
    id: "23", 
    name: "Strategie" 
  },
  { 
    cover: bg,
    id: "1",
    name: "Leadership" 
  },
  { 
    cover: bg,
    id: "34", 
    name: "Finanza" 
  },
  { 
    cover: bg,
    id: "45", 
    name: "Ricerca" 
  },
  { 
    cover: bg,
    id: "56", 
    name: "Strategy" 
  },
  { 
    cover: bg,
    id: "59", 
    name: "Web Analytics" 
  },
  { 
    cover: bg,
    id: "78", 
    name: "Analisi" 
  },
  { 
    cover: bg,
    id: "55", 
    name: "Creatività" 
  },
  { 
    cover: bg,
    id: "12", 
    name: "finanziaria" 
  }
];

export const USER_PROGRESS_FIELD = {
  HR_SUGGESTED:"hrSuggested",
  MANAGER_SUGGESTED:"managerSuggested",
  MANDATORY: "mandatory",
  SUGGESTED:"suggested",
  SUMMARY:"summary"
} as const;

export const KNOWLEDGE_AREAS = [
  { id: "23", name: "Strategie e business" },
  { id: "1", name: "Leadership e gestione di team" },
  { id: "34", name: "Mobile Banking" },
  { id: "45", name: "Ricerca e Sviluppo" },
  { id: "56", name: "Analisi e Strategie" },
  { id: "59", name: "Web Analytics" },
  { id: "78", name: "Analisi dei dati" },
  { id: "35", name: "Creatività e innovazione" },
  { id: "12", name: "Analisi finanziaria" }
];

export const PROFESSION = {
  MANAGER:"MANAGER",
  PROFESSIONAL:"PROFESSIONAL"
} as const;

export const ECM_USER = {
  EMPLOYEE:"EMPLOYEE",
  FREELANCE: "FREELANCE"
} as const;
