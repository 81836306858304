import {
  Grid,
  Stack,
  StepConnector,
  Stepper,
  Typography,
  TypographyProps
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ChangeEvent,
  PropsWithChildren,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation
} from "react-router-dom";

import type {
  GridItemProps,
  VerificationCode
} from "./Password.types";
import {
  ButtonText,
  CodeInput,
  GuardedLink,
  Icon,
  Link,
  Modal,
  PasswordInput,
  TextInput,
  Spinner
} from "../../components";
import { StyledStep } from "../../components/OnBoarding/OnBoarding.styles";
import { useNavigate } from "../../services";
import { useAuthStore } from "../../stores";
import type { FormErrors } from "../../types";
import { CustomRegex } from "../../utils/regex";

export function Password() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { state: routerState } = useLocation();
  const navigate = useNavigate();
  const changePassword = useAuthStore(state => state.changePassword);
  const confirmPassword = useAuthStore(state => state.confirmPassword);
  const completeNewPasswordChallenge = useAuthStore(state =>
    state.completeNewPasswordChallenge
  );
  const isLoading = useAuthStore(state => state.isLoading);
  const username = useAuthStore(state => state.user?.getUsername());
  const [newPassword, setNewPassword] = useState("");
  const [confirmPasswordText, setConfirmPasswordText] = useState("");
  const [errors, setErrors] = useState<FormErrors | null>(null);
  const [showChangePwdSuccess, setShowChangePwdSuccess] = useState(false);
  const steps = ["personal_data", "profession" , "personalization",  "summary"];
  const stepNumber = 0;

  return (
    <Stack
      alignItems={ isMobile ? "flex-start" : "center" }
      gap={ isMobile ? 3 : 6 }
      width={ isMobile ? "100%" : "min(90%, 30rem)" }
      minHeight={ isMobile ? "610px" : "auto" }
      onKeyDown={ (e)=>{
        if (e.key === "Enter") {
          e.preventDefault();
          handleSubmit();
        }
      } }
    >
      {
        routerState?.first ? (
          <Stepper
            connector={
              <StepConnector
                sx = { {
                  margin: theme.spacing(0, (isMobile ? 0.6 : 1))
                } }
              />
            }
            sx={ { width: "min(100%, 38rem)" } }
          >
            {
              steps.map((label, idx) => (
                <StyledStep
                  active={ stepNumber === idx }
                  component="span"
                  key={ `step--${idx}` }
                >
                  {
                    idx < stepNumber ? 

                      <Icon 
                        icon="Icons_modulo-completato" 
                        color={ theme.customColors.accentVariantB }
                        size={ 16 }
                      /> : null
                  }
                  { t(label) }
                </StyledStep>
              ))
            }
          </Stepper>
        ) : null
      }
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <Stack
              alignItems={ isMobile ? "flex-start" : "center" }
              gap={ isMobile ? 1 : 2 }
            >
            
              <Typography
                color="primary"
                fontSize={ isMobile ? "1.25rem" : "2rem" }
                fontWeight={ 700 }
                lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
              >
                {
                  routerState?.first || routerState?.oldPassword
                    ? `${t("set")} Password`
                    : `${t("reset")} Password`
                }
              </Typography>
              <Typography
                alignSelf="flex-start"
                fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                lineHeight={ isMobile ? (9 / 7) : (21 / 16) }
                textAlign={ isMobile ? "left" : "center" }
              >
                { t("safe_password_txt") }
              </Typography>
            </Stack>
            <PasswordInput
              error={ errors?.newPassword }
              helperText={ t("no_valid_password") }
              id="new-password"
              label={ `${t("new")} Password` }
              onBlur={ () => handleValidate(newPassword, confirmPasswordText) }
              onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
                setNewPassword(changeEvent.target.value);
                // handleValidate(changeEvent.target.value, confirmPasswordText);
              } }
              size={ isMobile ? "small" : "medium" }
              value={ newPassword }
              sx={ { width: "100%" } }
            />
            <PasswordInput
              error={ errors?.confirmPassword }
              helperText={ t("no_match_pw") }
              id="confirm-password"
              label={ t("confirm_password") }
              onBlur={ () => handleValidate(newPassword, confirmPasswordText) }
              onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
                setConfirmPasswordText(changeEvent.target.value);
                // handleValidate(newPassword, changeEvent.target.value);
              } }
              size={ isMobile ? "small" : "medium" }
              value={ confirmPasswordText }
              sx={ { width: "100%" } }
            />
            <Grid
              container
              rowGap={ isMobile? 2 : 1.5 }
              width="100%"

              sx={ {
                "div": {
                  flexFlow: isMobile ? "unset !important" : "wrap",

                  "&:last-of-type" : {
                    alignItems: "initial"
                  }
                }
                
              } }
            >
              <Grid
                item
                xs={ 12 }
                component={ Typography }
                fontSize={ isMobile ? "0.75rem" : "0.875rem" }
                lineHeight={ isMobile ? "0.75rem" : "1.125rem" }
                marginTop={ isMobile ? "8px" : "0" }
              >
                { t("pw_criteria_text") }:
              </Grid>
              <GridItem
                xs={ 6 }
                fontSize={ isMobile ? "0.75rem" : "0.875rem" }
                lineHeight={ isMobile ? "0.75rem" : "1.125rem" }
                active={ CustomRegex.testLength(newPassword, 8) }  
              >
                { t("pw_ch") }
              </GridItem>
              <GridItem
                xs={ 6 }
                fontSize={ isMobile ? "0.75rem" : "0.875rem" }
                lineHeight={ isMobile ? "0.75rem" : "1.125rem" }
                active={ CustomRegex.testUppercase(newPassword) }
              >
                { t("pw_cap_let") }
              </GridItem>
              <GridItem
                xs={ 6 }
                fontSize={ isMobile ? "0.75rem" : "0.875rem" }
                lineHeight={ isMobile ? "0.75rem" : "1.125rem" }
                active={ CustomRegex.testLowercase(newPassword) }
              >
                { t("pw_let") }
              </GridItem>
              <GridItem
                xs={ 6 }
                fontSize={ isMobile ? "0.75rem" : "0.875rem" }
                lineHeight={ isMobile ? "0.75rem" : "1.125rem" }
                active={ CustomRegex.testNumber(newPassword) }
              >
                { t("pw_num") }
              </GridItem>
              <GridItem
                xs={ 6 }
                fontSize={ isMobile ? "0.75rem" : "0.875rem" }
                lineHeight={ isMobile ? "0.75rem" : "1.125rem" }
                active={ CustomRegex.testSpecialCharacter(newPassword) }
              >
                { t("pw_special_ch") }
              </GridItem>
            </Grid>
            <GuardedLink
              disabled={ 
                !(CustomRegex.testPassword(newPassword) && confirmPasswordText === newPassword)
              }
              href="/"
              onClick={ () => { handleSubmit() } }
              state= { {
                ...routerState,
                password: newPassword,
                step: 0
              } }
              sx={ {
                borderRadius: isMobile ? "2px" : "4px",
                marginTop: isMobile ? "8px" : "0"
              } }
            >
              { t("confirm_password") }
            </GuardedLink>
          </>
        )
      }
      <Modal
        description={ "La password e' stata modificata con successo" }
        onClose={ () => {
          setShowChangePwdSuccess(false);
          navigate("/profile");
        } }
        open={ showChangePwdSuccess }
        title={ "" }
      />
    </Stack>
  );

  function handleValidate(password: string, confirmPassword: string) {
    /* TODO: use something like yup or zod for validation */

    const confirmPasswordIsValid = (confirmPassword === password);
    const newPasswordIsValid = CustomRegex.testPassword(password);

    setErrors({
      confirmPassword: !confirmPasswordIsValid,
      newPassword: !newPasswordIsValid
    });
    return { confirmPasswordIsValid, newPasswordIsValid };
  }

  /**
   * it is correct to both assign a stateful value (for rendering the error
   * messages in the jsx) and returning them from the validating function (to
   * proceed with the signal processing of the validation)
   * @returns the new value assigned to Errors
   */
  async function handleSubmit() {
    const {
      confirmPasswordIsValid,
      newPasswordIsValid
    } = handleValidate(newPassword, confirmPasswordText);
    if (!newPasswordIsValid || !confirmPasswordIsValid) {
      return false;
    } else if (routerState?.oldPassword && username) {
      setErrors(null);
      const { oldPassword } = routerState;
      const changePwdIsSuccess = await changePassword(oldPassword, newPassword);
      changePwdIsSuccess && setShowChangePwdSuccess(true);
      changePwdIsSuccess || navigate(
        "/access/password/change",
        { state: { ...routerState, oldPassword: "" } }
      );
    } else if (routerState?.username && routerState?.mfaCode) {
      setErrors(null);
      const { username, mfaCode } = routerState;
      confirmPassword(username, newPassword, mfaCode, "/");
    } else if (routerState?.firstAccess) {
      setErrors(null);
      const { userAttributes } = routerState;
      completeNewPasswordChallenge(
        newPassword,
        userAttributes
        // {
        //   state: routerState,
        //   target: "/"
        // }
      );
    } else {
      return false;
    }
  }
}

const StyledIcon = styled(Icon)(() => ({
  transform: "scale(1)"
}));

function GridItem({
  active=false,
  children,
  xs,
  fontSize
}: GridItemProps) {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={ xs }
      container
      alignItems="center"
      gap={ 1 }
    >
      <StyledIcon
        color={ active ? theme.customColors.systemSecondary02 : undefined }
        icon={ "Icons_modulo-completato" }
        size="1.25rem"
      />
      <Typography fontSize={ fontSize }>
        { children }
      </Typography>
    </Grid>
  );
}

export function PasswordChange() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { state: routerState } = useLocation();
  const [oldPassword, setOldPassword] = useState("");

  return (
    <Stack
      alignItems="center"
      gap={ isMobile ? 3 : 6 }
      width={ isMobile ? "100%" : "min(90%, 30rem)" }
    >
      <Stack
        alignItems={ isMobile ? "flex-start" : "center" }
        gap={ isMobile ? 1 : 2 }
        width={ isMobile ? "100%" : "auto" }
      >
        <Typography
          color="primary"
          fontSize={ isMobile ? "1.25rem" : "2rem" }
          fontWeight={ 700 }
          lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
        >
          { t("password_change") }
        </Typography>
        <Typography
          alignSelf="flex-start"
          fontSize={ isMobile ? "0.875rem" : "1.25rem" }
          lineHeight={ isMobile ? (9 / 7) : (21 / 16) }
          textAlign="center"
        >
          { t("enter_password") }
        </Typography>
      </Stack>
      <PasswordInput
        id="old-password"
        label={ t("password_old") }
        onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
          setOldPassword(changeEvent.target.value);
        } }
        size={ isMobile ? "small" : "medium" }
        value={ oldPassword }
        sx={ { width: "100%" } }
      />
      <Stack
        alignItems={ "center" }
        justifyContent="center"
        width={ "100%" }
        direction="row" gap={ 4 }>
        <Link href="/profile">
          <ButtonText variant="outlined">
            { t("cancel") }
          </ButtonText>
        </Link>
        <GuardedLink
          disabled={ !handleValidate(oldPassword) }
          href="/access/password"
          state= { {
            ...routerState,
            oldPassword
          } }
        >
          { t("send") }
        </GuardedLink>
      </Stack>
    </Stack>
  );

  function handleValidate(password: string) {
    return password.length > 0;
  }
}

export function PasswordRecovery() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { state: routerState } = useLocation();
  // const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const forgotPassword = useAuthStore(state => state.forgotPassword);
  const setLoadingSpinner = useAuthStore(state => state.setSpinnerLoginVisible);
  setLoadingSpinner(false);
  return (
    <Stack
      alignItems="center"
      gap={ isMobile ? 3 : 6 }
      width={ isMobile ? "100%" : "min(90%, 30rem)" }
      onKeyDown={ (e)=>{
        if(e.key === "Enter"){
          e.preventDefault();
          // handleSubmit(email);
          handleSubmit(username);
        }
      } }
    >
      <Stack
        alignItems="center"
        gap={ isMobile ? 1 : 2 }
        width={ isMobile ? "100%" : "auto" }
      >
        <Typography
          color="primary"
          fontSize={ isMobile ? "1.25rem" : "2rem" }
          fontWeight={ 700 }
          lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
        >
          { t("password_recovery") }
        </Typography>
        <Typography
          alignSelf="flex-start"
          fontSize={ isMobile ? "0.875rem" : "1.25rem" }
          lineHeight={ isMobile ? (9 / 7) : (21 / 16) }
          textAlign= "center"
        >
          { t("password_recovery_instructions") }
        </Typography>
      </Stack>
      <TextInput
        id="new-password"
        label={ t("username") }
        onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
          // setEmail(changeEvent.target.value);
          setUsername(changeEvent.target.value);
        } }
        size={ isMobile ? "small" : "medium" }
        // value={ email }
        value={ username }
        sx={ { width: "100%" } }
      />
      <Stack
        alignItems={ "center" }
        direction="row" gap={ isMobile ? 2 : 4 }
        width={ isMobile ? "100%" : "auto" }>
        
        <Link href="../../">
          <ButtonText variant="outlined" sx={ { maxHeight: isMobile ? "40px" : "auto" } }>
            { t("cancel") }
          </ButtonText>
        </Link>
        <GuardedLink
          // disabled={ !handleValidate(email) }
          disabled={ !handleValidate(username) }
          href="/access/password/code"
          onClick={ async() => {
            // const success = await handleSubmit(email);
            const success = await handleSubmit(username);
            return success;
          } }
          state= { {
            ...routerState,
            recover: true,
            username
          } }
        >
          { t("send") }
        </GuardedLink>
      </Stack>
    </Stack>
  );

  // function handleValidate(email: string) {
  function handleValidate(username: string) {
    // return CustomRegex.matchEmail(email);
    return username.length > 0;
  }

  function handleSubmit(email: string) {
    return handleValidate(email) && forgotPassword(email,false);
  }
}

// const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
//   color: theme.customColors.textPrimary
// }));

// const StyledRadio = styled(Radio)(() => ({
//   "[data-testid=RadioButtonCheckedIcon]": {
//     transform: "scale(0.9)"
//   },
//   "[data-testid=RadioButtonUncheckedIcon]": {
//     transform: "scale(1.2)"
//   }
// }));

// export function PasswordPreCode() {
//   const { state: routerState } = useLocation();
//   const [error, setError] = useState(false);
//   const [method, setMethod] = useState<string | null>(null);
//   const [contact, setContact] = useState("");
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const { t } = useTranslation();

//   return (
//     <Stack
//       alignItems="center"
//       gap={ isMobile ? 3 : 6 }
//       width="min(90%, 30rem)"
//     >
//       <Stack
//         alignItems="center"
//         gap={ isMobile ? 1 : 2 }
//       >
//         <Typography
//           color="primary"
//           fontSize={ isMobile ? "1.25rem" : "2rem" }
//           fontWeight={ 700 }
//           lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
//           textAlign="center"
//         >
//           { t("authentication_method") }
//         </Typography>
//         <Typography
//           fontSize={ isMobile ? "0.875rem" : "1.25rem" }
//           lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
//         >
//           { t("receiving_code") }
//         </Typography>
//       </Stack>
//       <StyledRadio
//         labelId="select-verification-code"
//         name="select-verification-code"
//         onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
//           setMethod(changeEvent.target.value);
//         } }
//         options={ [
//           { label: "SMS" },
//           { label: "E-mail" }
//         ] }
//         value={ method }
//         sx={ {
//           gap: (isMobile ? 0 : 2),
//           margin: theme.spacing(0, 3)
//         } }
//       />
//       {
//         method ? (
//           <FormControl
//             fullWidth
//             sx={ { gap: 0.5 } }
//           >
//             <StyledFormLabel
//               id="contact-input"
//               sx={ {
//                 fontSize: isMobile ? "0.875rem" : "1rem",
//                 lineHeight: (21 / 16)
//               } }
//             >
//               { t(getContactLabel(method) || "") }
//             </StyledFormLabel>
//             <TextInput
//               aria-labelledby="contact-input"
//               error={ error }
//               helperText={ t("sms_verify") }
//               onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
//                 if (
//                   method === "SMS"
//                   && isNaN(+changeEvent.target.value)
//                   && changeEvent.target.value !== ""
//                 ) {
//                   setError(true);
//                 } else {
//                   setError(false);
//                   setContact(changeEvent.target.value);
//                 }
//               } }
//               size={ isMobile ? "small" : "medium" }
//               value={ contact }
//             />
//           </FormControl>
//         ) : null
//       }
//       <GuardedLink
//         href="/access/password/code"
//         onClick={ () => {
//           return (contact.length > 0);
//         } }
//         state= { {
//           ...routerState,
//           password: routerState?.password,
//           step: 0
//         } }
//       >
//         { t("send") }
//       </GuardedLink>
//     </Stack>
//   );
// }

// function getContactLabel(method: string) {
//   switch(method) {
//   case "SMS":
//     return "phone_enter";
//   case "E-mail":
//     return "email_enter";
//   default:
//     return null;
//   }
// }

export function PasswordCode() {
  const CODE_LEN = 6;
  const { t } = useTranslation();
  const { state: routerState } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const authIsLoading = useAuthStore(state => state.isLoading);
  const completeCustomChallenge = useAuthStore(state => state.completeCustomChallenge);
  const forgotPassword = useAuthStore(state => state.forgotPassword);
  const [code, setCode] = useState<VerificationCode<typeof CODE_LEN>>(
    Array(CODE_LEN).fill(NaN) as VerificationCode<typeof CODE_LEN>
  );
  const [tentative, setTentative]= useState(1);

  // const codeIsValid = code?.every((digit) => (parseInt(digit) >=0));
  const codeIsValid = code?.every((character) => (Boolean(character)));

  function setPastedValue(value:string[]){
    const valueToSet = value?.slice(0,CODE_LEN).map((value)=> value);
    setCode(valueToSet as VerificationCode<typeof CODE_LEN> );
  }

  document.addEventListener("paste",(e) => {
    const value = e.clipboardData?.getData("Text")?.split("");
    setPastedValue(value || [""]);
    e.preventDefault();
  
  });

  return authIsLoading ? (
    <Spinner />
  ) : (
    <Stack
      alignItems={ isMobile ? "flex-start" : "center" }
      gap={ isMobile ? 3 : 6 }
      width={ "100%" }
    >
      <Stack
        alignItems={ isMobile ? "flex-start" : "center" }
        gap={ isMobile ? 0 : 4 }
      >
        <Typography
          color="primary"
          fontSize={ isMobile ? "1.25rem" : "2rem" }
          fontWeight={ 700 }
          lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
          marginBottom={ isMobile ? "8px" : "0" }
          textAlign="center"
        >
          { t("account_verify") }
        </Typography>
        <Typography
          fontSize={ isMobile ? "0.875rem" : "1.25rem" }
          lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
          textAlign={ isMobile ? "left" : "center" }
        >
          { routerState?.recover ? t("recover_message") : t("code_mail_send") }
        </Typography>
        { !routerState?.recover && <Typography
          alignSelf="flex-start"
          fontSize={ isMobile ? "0.875rem" : "1.25rem" }
          lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
          marginBottom={ isMobile ? "8px" : "0" }
          textAlign={ isMobile ? "left" : "center" }
        >
          { t("email_check") }
        </Typography> }
      </Stack>
      <Stack
        alignItems="center"
        gap={ isMobile ? 3 : 4 }
      >
        <Stack
          direction="row"
          gap={ isMobile ? 1 : 2.5 }
          justifyContent={ isMobile ? "flex-start" : "center" }
        >
          {
            [...Array(CODE_LEN)].map((_, pos) => (
              <CodeInput
                value={ 
                  code?.find((_,index)=>  pos === index) || "" 
                }
                key={ `code-box--${pos}` }
                boxSize={ isMobile ? "40px" : undefined }

                onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
                  setCode((prev) => {
                    prev[pos] = changeEvent.target.value;
                    return [ ...prev ];
                  });

                } }
              />
            ))
          }
        </Stack>
        <Typography
          alignSelf="flex-start"
          fontSize={ isMobile ? "0.875rem" : "1.125rem" }
          lineHeight={ isMobile ? "1.28571rem" : (11 / 9) }
          textAlign="center"
        >

          { t("no_code_received") } ? 
          <ButtonTypography
            onClick={ ()=> { requestCode()} }
          >
            { " " + t("request_code") }
          </ButtonTypography>
        </Typography>
      </Stack>
      <GuardedLink
      /* cfr. note in sendCode */
        disabled={ !codeIsValid }
        href={ routerState?.recover ? "/access/password" : "/" }
        onClick={ sendCode }
        state= { {
          ...routerState,
          mfaCode: code.join("")
        } }
      >
        { t("confirm") }
      </GuardedLink>
    </Stack>
  );

 

  /***
   * the navigation of this component supports 2 different cases:
   * 1) this is a cognito mfa challenge, in this case the link navigation 
   *    is always disabled and the click starts an async handler that 
   *    sets an auth store navigation object handled in the root page 
   *    navigation
   * 2) this is a password recovery attempt, in this case if the input 
   *    code is valid the the link navigates to the password change page 
   *    while the router state is joined with the code itself and forwarded 
   *    to the next page
  */

  function sendCode() {
    if (routerState?.recover) {
      return codeIsValid;
    } else {
      setTentative((prev)=> 
        prev + 1
      );
      completeCustomChallenge(
        code.join(""),
        { "tentative":tentative.toString() 
        },
        {
          state: {},  
          target: "/"
        },
        true
      );
      return false;
    }
  }

  function requestCode() {
    if(routerState.recover && routerState?.username){
      forgotPassword(routerState.username,true);
    }
    else {
      completeCustomChallenge(
        code.join(""),
        { "tentative":"0" 
        },
        {
          state: {},  
          target: "/"
        }
      );
    }
    
    return false;
  
  }

  // function handleValidate(username: string) {
  //   // return CustomRegex.matchEmail(email);
  //   return username.length > 0;
  // }
}

function ButtonTypography({
  children,
  ...props
}: PropsWithChildren<TypographyProps>) {
  return (
    <Typography
      component="span"
      color="primary"
      fontSize="inherit"
      sx={ { cursor: "pointer" } }
      { ...props }
    >
      { children }
    </Typography>
  );
}
