import {
  Stack,
  Typography,
  Grid,
  Box,
  GridProps
} from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useOutletContext
} from "react-router-dom";
import type { CardCtaProps } from "./CardCta.types";
import {
  ButtonText,
  GuardedLink,
  Icon,
  Link,
  Modal,
  Progress,
  Spinner
} from "../../components";
import {
  CONTENT_TYPE,
  ENROLL_TYPE,
  LEVEL,
  STATUS_LO
} from "../../consts";
import {
  useButtonStatusMap,
  useResumeLink
} from "../../hooks";
import {
  useEnrollmentMutation,
  useFavouritesMutation,
  useInvalidateLearningObjectQuery,
  useRatingQuery
} from "../../queries";
import { OutletContext } from "../../routes";
import { useAuthStore } from "../../stores";
import { getError } from "../../utils/error";
import { getLearningObjectType } from "../../utils/general";
import { CtaOverlayIcon } from "../CtaOverlay/CtaOverlay.styles";

const CardCtaGrid = styled(Grid)<
  GridProps & { $vertical?: boolean}
>(({ $vertical=false, theme }) => ({
  alignItems: $vertical ? undefined : "center",
  flexDirection: $vertical ? "column" : undefined,
  position:"relative",
  transition: "gap .3s linear",
  zIndex: theme.zIndex.appBar
}));

export const StyledBoxContact = styled(Box)<{ $visible: boolean }>(({ theme, $visible })=> ({
  alignItems: "center",
  background: theme.customColors.backgroundSecondary,
  borderRadius: "2px",
  boxShadow: "8px 8px 15px rgba(0, 0, 0, 0.15)",
  color:theme.customColors.textInput,
  display:$visible ?  "flex" : "none",
  gap: theme.spacing(3),
  justifyContent: "space-between",
  maxHeight: $visible ? "auto" : 0,
  maxWidth: "450px",
  opacity: $visible ? 1 : 0,
  padding: $visible ? theme.spacing(1,2) : theme.spacing(0,2) ,
  position:"absolute",
  top:0,
  transition: "all .3s linear",
  width:"fit-content",
  zIndex: theme.zIndex.mobileStepper,

  ".icon": {
    cursor: "pointer",
    minWidth:"19px"
  },

  "> *": {
    maxHeight: $visible ? "auto" : 0
  }
}));

export const StyledSnackBarFavourite = styled(Box)<{ $visible: boolean }>(({ theme, $visible })=> ({
  alignItems: "center",
  background: theme.customColors.backgroundSecondary,
  borderRadius: "2px",
  boxShadow: "8px 8px 15px rgba(0, 0, 0, 0.15)",
  color:theme.customColors.textInput,
  display:"flex",
  gap: theme.spacing(3),
  justifyContent: "space-between",
  maxHeight: $visible ? "auto" : 0,
  maxWidth: "450px",
  opacity: $visible ? 1 : 0,
  padding: $visible ? theme.spacing(1,2) : theme.spacing(0,2) ,
  pointerEvents:$visible ? "auto" : "none",
  position:"absolute",
  right:0,
  top:"-3.15rem",
  transition: "all .3s linear",
  width:"fit-content",
  zIndex: theme.zIndex.mobileStepper,

  ".icon": {
    cursor: "pointer",
    minWidth:"19px"
  },

  ">p, >a":{
    fontSize:"0.75rem"
  },

  "> *": {
    maxHeight: $visible ? "auto" : 0,
    opacity:$visible ? "auto" : 0
  }
}));

type ButtonActionType = {
  enabled:boolean,
  function?:() => void
}

export function CardCta({
  badge,
  certificate,
  courseId=null,
  disabled,
  disableFavourites,
  ecmRegistration,
  enrollType,
  id,
  isBookmarkPage=false,
  isCard = false,
  isSurvey,
  isTest,
  madeAttempts,
  ecm_specialization,
  isExpired,
  isStandAlone,
  labelMoreInfo = false,
  learningObjectType,
  learningObjectTypology,
  linkVirtualClass,
  percentageOfCompletion,
  parentId, // for favourites
  grandParentId, // for favourites
  rootId=null,
  setOpenRegistrationModal,
  setOpenModalEcm,
  setOpenTojModal,
  status,
  simple=false,
  triggerOpenTabs,
  vertical=false,
  ...props
}: CardCtaProps ) {
  const { sectionUrl } = useOutletContext<OutletContext>();
  const { t } = useTranslation();
  const navigate= useNavigate();
  const buttonText = useButtonStatusMap({
    ecm_specialization:ecm_specialization,
    ecmRegistration,
    enrollType,
    isCard,
    isSurvey:isSurvey,
    isTest:isTest,
    learningObjectType,
    learningObjectTypology,
    madeAttempts:madeAttempts,
    percentageOfCompletion
  });
  
  const theme = useTheme();
  const isLoading = useAuthStore(state => state.isLoading);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [actionButton, setActionButton] = useState<ButtonActionType>({ 
    enabled: true, 
    function: undefined 
  });

  const resumeLink = useResumeLink({
    ecm_specialization,
    ecmRegistration,
    enrollType,
    id,
    labelMoreInfo,
    learningObjectType,
    learningObjectTypology,
    sectionUrl,
    status
  });

  const { invalidateLearningObject } = useInvalidateLearningObjectQuery({
    courseId: courseId,
    id: id,
    learningObjectTypology: learningObjectTypology,
    rootId: rootId
  });
  
  const { data: ratingStatistic } = useRatingQuery({
    learningObjectId: id,
    learningObjectTypology: getLearningObjectType(learningObjectTypology)
  });

  const idEnrollment = grandParentId ? 
    grandParentId : parentId ? 
      parentId : rootId ? 
        rootId : courseId ? 
          courseId : id;
          
  const resumeLinkOpenEsternal = learningObjectTypology === LEVEL.DA_LINKEDIN || 
  learningObjectTypology === LEVEL.DA_URL_RES ? 
    `/esplora/scorm/${id}/${learningObjectTypology}?courseId=${courseId}&rootId=${rootId}` : 
    undefined;

  const typologyEnrollment = rootId ? LEVEL.PATH : courseId ? LEVEL.COURSE : learningObjectTypology;

  const {
    mutate: addEnrollmentMutation,
    error: enrollmentMutationError,
    isError: enrollmentMutationIsError,
    isSuccess: enrollmentMutationHadSuccess,
    isLoading: enrollmentIsLoading
  } = useEnrollmentMutation({
    enrollType:enrollType,
    learningObjectId: idEnrollment,
    learningObjectTypology: typologyEnrollment
  });

  // TO ADD IN PARAMS IN FAVOURITES RETRIEVED FROM LO
  const favParentId = !isStandAlone ? (!courseId && rootId) ? rootId : courseId ?? null : null;

  const favGrandParentId = (!isStandAlone 
      && favParentId 
      && favParentId !== rootId
  ) ? rootId : null ;

  const { 
    mutate: addFavouriteMutation, 
    isSuccess:isFavouriteAdded, 
    isLoading:isAddingFavouriteLoading 
  } = useFavouritesMutation({
    grandParentId:favGrandParentId,
    learningObjectId: id,
    learningObjectTypology,
    method: "POST",
    parentId:favParentId
  });

  const { 
    mutate: removeFavouriteMutation, 
    isSuccess:isFavouriteRemoved, 
    isLoading:isRemovingFavouriteLoading 
  } = useFavouritesMutation({
    learningObjectId: id,
    learningObjectTypology,
    method: "DELETE"
  });
 
  const loStatistic = {
    userFavourite: Boolean(ratingStatistic?.userRating.isFavouriteForLearner && 
      ratingStatistic?.objectStatistics?.learningObjectId)
  };

  const resumeContent = useCallback(() => {
    navigate(
      resumeLink,
      {
        state: { courseId: courseId ?? parentId, rootId:rootId ?? grandParentId }
      }
    );
  }, [
    courseId, 
    grandParentId, 
    navigate, 
    parentId, 
    resumeLink, 
    rootId]);

  useEffect(()=> {
    if(isFavouriteAdded && loStatistic.userFavourite){
      setSnackbarVisible(isFavouriteAdded);
      setTimeout(()=> {
        setSnackbarVisible(false);
      },5000);
    }
    else {
      setSnackbarVisible(false);
    }

  },[
    isFavouriteAdded, 
    isFavouriteRemoved, 
    loStatistic.userFavourite
  ]);

  useEffect(() => {
    if (enrollmentMutationHadSuccess && !enrollmentIsLoading ) {
      if(enrollType === "AUTO_ENROLL"){
        if(resumeLinkOpenEsternal){
          window.open(resumeLinkOpenEsternal, "_blank", "noreferrer");
        }
        else {
          resumeContent();
        }
      } else {
        invalidateLearningObject(); 
      }
    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enrollType,
    enrollmentIsLoading,
    enrollmentMutationHadSuccess, 
    navigate, 
    resumeLink, 
    courseId, 
    rootId, 
    resumeLinkOpenEsternal
  ]);

  useEffect(() => {
    enrollmentMutationIsError && setShowModal(true);
  }, [enrollmentMutationIsError]);

  useMemo(()=> {
    // case -1 : TOJ activity
    if((status === "E" || status === "P" || status === "C") && setOpenTojModal
    && (learningObjectTypology === LEVEL.DA_FINAL_BALANCE
    || learningObjectTypology === LEVEL.DA_GOALS
    || learningObjectTypology === LEVEL.DA_MATERIAL
    || learningObjectTypology === LEVEL.DA_MEETING
    )
    
    ){
      setActionButton(
        {
          enabled:false,
          function: ()=> { 
            setOpenTojModal(true);
          } 
        }
      );
    }
    // case 0 : ALL ecm LO enrolled and not registered
    else if( status === "E" && ecm_specialization && !ecmRegistration && setOpenRegistrationModal ){
      setActionButton(
        {
          enabled:false,
          function: ()=> { 
            setOpenRegistrationModal(true);
          } 
        }
      );

    }
    else if (
      // case 1 : LIVE  and VIRTUAL LO in HeroBanner
      learningObjectType === CONTENT_TYPE.SYNC
      && triggerOpenTabs
      && !labelMoreInfo
    ) {

      if (
        learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
        && status !== "T"
        && status !== "N"
      ) {
        // if enrolled ==>  VIRTUAL LO 
        setActionButton({
          enabled: true,
          function:()=> {
            // for  virtual LO  Enrolled in HeroBanner to open external link metting if is available:
            if(linkVirtualClass){
              window.open(linkVirtualClass, "_blank", "noreferrer"); 
            } else {
              // TO DO:  UNNECESSARY TO USE RESUMECONTENT()
              resumeContent();
            }
            
          }
        });
      } else {
        // if  NOT  enrolled : VIRTUAL LO && LIVE LO, action ==> OPEN TABS EDITIONS
        setActionButton({
          enabled:false,
          function: ()=> { 
            triggerOpenTabs();
          }
        });
      }
    } else if (
    // case 2 : OTHER LO not enrolled 
      status === "N"
        && !labelMoreInfo 
        && !isExpired
    ) {
      // if enrollment action possible
      if (
        enrollType === ENROLL_TYPE.AUTO_ENROLL || enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL
      ) { 
        setActionButton(
          {
            enabled:false,
            function: ()=> { 
              // requestedAutoenroll possible only in details page
              if (isCard && enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL) {
                resumeContent();
              } else {
                addEnrollmentMutation();
              }
            } 
          }
        );
      
      } else if(!isCard) {
        // TOP_DOWN CASE in hero banner (open banner ect..), enrollment action IS NOT possible  
        setActionButton(
          {
            enabled:false,
            function:  ()=> {
              setSendRequest(true);
              setBannerVisible(true);
            }
          }
        );
      }
      else {
        // TOP_DOWN CASE in card(navigate to detail..), enrollment action IS NOT possible  
        setActionButton({
          enabled:true,
          function:()=> {
            resumeContent();
          }
        });
      }
    } else {
      // case 3 : OTHER LO enrolled 
      setActionButton({
        enabled:true,
        function:()=> {
          if(resumeLinkOpenEsternal && (
            (learningObjectTypology === LEVEL.DA_URL_RES && !isCard) ||
            (learningObjectTypology === LEVEL.DA_LINKEDIN && resumeLink.includes("guarda"))
          )){
            window.open(resumeLinkOpenEsternal, "_blank", "noreferrer");
          }
          else {
            resumeContent();
          }
        }
      });
    }
   
  }, [
    status, 
    ecm_specialization, 
    ecmRegistration, 
    setOpenRegistrationModal, 
    learningObjectType, 
    triggerOpenTabs, 
    labelMoreInfo, 
    isExpired, 
    learningObjectTypology, 
    linkVirtualClass, 
    resumeContent, 
    enrollType, 
    isCard, 
    addEnrollmentMutation, 
    resumeLinkOpenEsternal,
    resumeLink,
    setOpenTojModal
  ]);
  

  return (
    <CardCtaGrid
      item
      container
      gap={ bannerVisible ? 1 : 0 }
      $vertical={ vertical }
      overflow={ snackbarVisible || bannerVisible ? "visible !important" : "hidden !important" }
      { ...props }
    >
      <StyledSnackBarFavourite
        $visible={ snackbarVisible }
      >
        <Icon 
          icon="Icons_Preferiti" 
          size={ 19 } 
          color={ theme.customColors.textPrimaryAlternative }
        />
        <Typography
          color= { theme.customColors.textPrimaryAlternative }
          fontSize = { theme.spacing(1.625) }
          fontWeight= { 500 }
        >
          { t("item_added_favourites") }
          <Link href="/bookmarks" fontWeight={ 700 } >
            { " " + t("favourites") }
          </Link>
        </Typography>
        <Icon
          icon="close"
          size={ 18 }
          color= { theme.palette.primary?.main }
          onClick={ ()=> { setSnackbarVisible(false) } }
        />
      </StyledSnackBarFavourite>
      <StyledBoxContact
        $visible={ bannerVisible  }
        width="100%"
        sx={ {
          left:0,
          right:"unset",
          top:"-40px"
        } }
      >
        <Typography
          color= { theme.customColors.textPrimaryAlternative }
          fontSize = { theme.spacing(1.625) }
          fontWeight= { 500 }
        >
          { bannerVisible ? t("contact_text") : "" }
        </Typography>
        <Icon
          icon="close"
          size={ 19 }
          color= { theme.palette.primary?.main }
          onClick={ ()=> { setBannerVisible(false) } }
        />
      </StyledBoxContact>
      <Stack
        alignItems="center"
        direction="row"
        gap={ 1 }
        width="100%"
        { ...props }
        sx={ {
          ">button, >a":{
            pointerEvents:setOpenModalEcm && disabled ? "none" : "auto",
            zIndex:setOpenModalEcm && disabled ? "-1" : undefined
          }
        } }
        onClick={ ()=> {
          if(setOpenModalEcm && disabled && ecm_specialization && !ecmRegistration){
            setOpenModalEcm(true);
          }
        } }
      >
        {
          (
            !simple
            && percentageOfCompletion
            && (status === STATUS_LO.P || status === STATUS_LO.C)
            && (
              learningObjectTypology !== LEVEL.DA_GOALS 
              && learningObjectTypology !== LEVEL.DA_MEETING
              && learningObjectTypology !== LEVEL.DA_FINAL_BALANCE
              && learningObjectTypology !== LEVEL.DA_MATERIAL
            )
          )
            ? (
              <Progress
                flexGrow={ 1 }
                maxWidth={ "293px" }
                percentageOfCompletion={ percentageOfCompletion }
              />
            ) : null
        }
        <GuardedLink
          disabled={ disabled || 
            ((status === STATUS_LO.W 
            || status === STATUS_LO.R
            ) 
            && !labelMoreInfo 
            && !isCard)  
          }
          disableLink={ true }
          href={ resumeLink }
          onClick={ ()=> {    
            if(actionButton.function){
              actionButton.function();
            }
          } }
          sx={ { flexGrow: 1 } }
          state={ {
            courseId:courseId,
            rootId:rootId
          } }
        >
          { 
            enrollmentIsLoading && (!enrollmentMutationHadSuccess || enrollmentMutationError)
              ? <Spinner size={ 20 } padding={ 0 }/>
              : getLinkLabel() 
          }
        </GuardedLink>
        {
          (
            ( !simple || isBookmarkPage ) &&  
            (
              smartConfiguration?.funcFavourites
              && !isLoading
            ) && !disableFavourites
          ) ? 
            (
              <ButtonText
                disabled={ disabled }
                className={ "bookmark" }
                onClick={ () => {
                  if (loStatistic.userFavourite) {
                    removeFavouriteMutation();
                  } else {
                    addFavouriteMutation();
                  }
                } }
                variant={ "outlined" }
                sx={ {
                  minWidth:"unset",
                  padding: "10px"
                } }
              >
                {
                  
                  (isAddingFavouriteLoading || isRemovingFavouriteLoading ) ? 
                    <Spinner size={ 20 } padding={ 0 }/> : 
                    (loStatistic.userFavourite) ? (
                      <CtaOverlayIcon 
                        icon={  "Icons_salva-preferito_glow" } 
                        size={ 20 } 
                      />
                    ) : (
                      <CtaOverlayIcon 
                        icon={ "plus" } 
                        size={ 20 } 
                      />
                    )
                }
              </ButtonText>
            ) : null
        }
        {   
          (badge || certificate) ? (
            <Box
              onClick={ (e)=> {
                if(badge?.isEnable || certificate?.isEnable){
                  navigate("/profile");
                } else {
                  e.preventDefault();
                }
              } }
              sx={ {
                cursor:(badge?.isEnable || certificate?.isEnable) ? "pointer" :"not-allowed"                 
              } }
            >
              <Icon
                color={ (badge?.isEnable || certificate?.isEnable) ? 
                  theme.customColors.systemPrimary02 : 
                  theme.customColors.textPrimary }
                icon={ "Icons_security_03" }
                size={ 24 }
                style={ { 
                  marginLeft: theme.spacing(0.2) 
                } }
          
              />
            </Box>
          ) : null
        }
      </Stack>
      <Modal
        open={ showModal && enrollmentMutationIsError }
        onClose={ ()=> { setShowModal(false) } }
        description={ getError(enrollmentMutationError)?.[0]?.message ?? "" }
        title={ getError(enrollmentMutationError)?.[0]?.name ?? "" }
      />
    </CardCtaGrid>
  );

  function getLinkLabel() {
    if (sendRequest) {
      return t("validation_text");
    } else if (labelMoreInfo) {
      return t("more_info");
    } else {
      return t(buttonText[status]?.label);
    }
  }
}
