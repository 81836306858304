import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  cloneElement,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  StyledTab,
  StyledTabBox,
  StyledTabContent
} from "./Tabs.styles";
import type { TabsProps } from "./Tabs.types";
import { MAIN_LATERAL_PADDING } from "../../consts";

export function Tabs({
  contents,
  titleInset=false
}: TabsProps) {
  const [valueSelected, setValueSelected]=useState(false);
  const [value, setValue] = useState(contents[0]?.type);
  const { pathname } = useLocation();
  const theme = useTheme();
  const { t }=useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isToHide = pathname.includes("guarda") && !isMobile;

  useEffect(()=> {
    setValueSelected(false);
  },[pathname]);

  useEffect(()=> {
    if(!valueSelected){
      setValue( contents[0]?.type);
    }
  }, [contents, valueSelected, pathname]);

  return (
    <StyledTab className={ `tab-component ${isToHide ? "hidden" : ""}`  }>
      <TabContext value={ value } >
        <StyledTabBox
          sx={ titleInset ? {
            transform: isMobile ? "translateX(20px)" : `translateX(${MAIN_LATERAL_PADDING}px)`
          } : undefined }>
          <TabList
            onChange={ (_, newValue) => {
              setValue(newValue);
              setValueSelected(true);
            } }
            aria-label="lab API tabs example"
            allowScrollButtonsMobile={ true }
            scrollButtons={ false }
            variant={ "scrollable" }

          >
            {
              contents.map(({ label, type }, idx)=> {
                return (
                  <Tab
                    className={ type }
                    key={ `${label}-${idx}` }
                    label={ t(label) }
                    value={ type }
                    sx={ {
                      fontSize: "1.5rem !important",
                      paddingY: `${theme.spacing(1)} !important`,
                      textTransform: "initial",

                      [theme.breakpoints.down("sm")]: {
                        fontSize: "1rem !important",
                        lineHeight: "1.3125rem"
                      },

                      ".Mui-selected": {
                        fontWeight: "500 !important"
                      }
                    } }
                  />
                );
              })
            }
          </TabList>
        </StyledTabBox>
        {
          contents.map(({ components, label, type }, idx)=> (
            <StyledTabContent
              key={ `${label}-${idx}` }
              value={ `${type}` }
            >
              {
                components?.map((Element, index) => {
                  return cloneElement(Element, { key: `itemElement-${index}` });
                })
              }
            </StyledTabContent>
          ))
        }
      </TabContext>
    </StyledTab>
  );
}
